import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Card from '@/components/common/Card'
import LinkButton1 from '@/components/common/LinkButton1'

const TypeList = props => {
  return (
    <Root>
      <Main>
        <List>
          <List_Item
            title={(
              <Fragment>コーポレートサイト</Fragment>
            )}
            body={(
              <Fragment>問い合わせ数／資料ダウンロード数／採用応募数／サービスサイトへの誘導数など、業種・業態に合わせた戦略的なコーポレートサイトを制作します。新規制作だけではなく、リニューアルも弊社にお任せください。</Fragment>
            )}
            caption={(
              <Fragment>費用：25万円〜</Fragment>
            )}
            link={{
              href: encodeURI('/web/works/?type=コーポレートサイト'),
              text: '実績を見る'
            }}
          />
          <List_Item
            title={(
              <Fragment>メディアサイト</Fragment>
            )}
            body={(
              <Fragment>情報発信するためのサイトを制作します。認知獲得／見込み客の育成／サービスや商品ページへの流入数増加などの効果を狙うことができます。弊社では実際にメディア運用をしているため、より具体的な提案や運用時の負担が少ないサイトを制作することが可能です。</Fragment>
            )}
            caption={(
              <Fragment>費用：40万円〜</Fragment>
            )}
            link={{
              href: encodeURI('/web/works/?type=メディア'),
              text: '実績を見る'
            }}
          />
          <List_Item
            title={(
              <Fragment>ECサイト</Fragment>
            )}
            body={(
              <Fragment><a href="https://www.shopify.jp/" target="_blank" rel="noopener noreferrer">shopify</a>を使ったネットショップの開設〜運用・サポートまで対応します。「IT導入補助金」や「小規模事業者持続化補助金」の申請にも対応しています。依頼主様の作業負担・費用負担を最小限に抑え、高品質なECサイト構築をお手伝いします。</Fragment>
            )}
            link={{
              href: 'https://frontvision-consulting.com/shopify/',
              external: true,
              text: '詳しくはこちら'
            }}
          />
          <List_Item
            title={(
              <Fragment>サービスサイト</Fragment>
            )}
            body={(
              <Fragment>商品やサービスの紹介や販売をするためのサイトを制作します。プレスリリース／メルマガ／SNS／広告設定など、継続的に成果を上げるために必要な運用の支援も可能です。</Fragment>
            )}
            caption={(
              <Fragment>費用：25万円〜</Fragment>
            )}
            link={{
              href: encodeURI('/web/works/?type=サービスサイト'),
              text: '実績を見る'
            }}
          />
          <List_Item
            title={(
              <Fragment>ポータルサイト</Fragment>
            )}
            body={(
              <Fragment>特定ジャンルの企業、店舗、施設等の紹介情報を集約したポータルサイトを制作します。</Fragment>
            )}
            caption={(
              <Fragment>費用：100万円〜</Fragment>
            )}
            link={{
              href: encodeURI('/web/works/?type=ポータルサイト'),
              text: '実績を見る'
            }}
          />
          <List_Item
            title={(
              <Fragment>LP（ランディングページ）</Fragment>
            )}
            body={(
              <Fragment>お問い合わせ／資料ダウンロード／商品の購入など、サイト訪問者を問い合わせや購入に誘導することに特化した1枚のWEBページを作成します。</Fragment>
            )}
            caption={(
              <Fragment>費用：25万円〜</Fragment>
            )}
            link={{
              href: encodeURI('/web/works/?type=LP'),
              text: '実績を見る'
            }}
          />
        </List>
      </Main>
      <Foot>
        <Button component={Link} to="/web/works/" type="contrast">実績を見る</Button>
      </Foot>
    </Root>
  )
}

const Root = styled.div``

const Main = styled.div`
  @media ${mq.and(tabletL)} {
  }
  @media ${mq.and(tabletS)} {
  }
  @media ${mq.and(mobile)} {
  }
`

const Foot = styled.div`
  text-align: center;
  margin-top: 64px;
  @media ${mq.and(mobile)} {
    margin-top: 40px;
  }
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const List_Item = styled(Card)`
  width: 50%;
  @media ${mq.and(tabletS, mobile)} {
    width: 100%;
  }
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

export default ThemeConsumer(TypeList)
